var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "login"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "login-header"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('p', {
    staticClass: "text-white"
  }, [_vm._v("Your session ends in: " + _vm._s(_vm.timerString))])])]), _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('sygni-container-title', {
    attrs: {
      "line-height": 4
    }
  }, [_vm._v("Confirm your login")]), _c('div', {
    staticClass: "login-form"
  }, [_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'ENTER GOOGLE AUTHENTICATOR CODE',
      "validation": _vm.$v.confirmationCode,
      "type": "text"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.sendConfirmationCode.apply(null, arguments);
      }
    },
    model: {
      value: _vm.confirmationCode,
      callback: function callback($$v) {
        _vm.confirmationCode = $$v;
      },
      expression: "confirmationCode"
    }
  }), _vm._m(0), _c('sygni-rounded-button', {
    staticClass: "gn-secondary filled",
    attrs: {
      "type": "submit",
      "loading": _vm.isLoading
    },
    on: {
      "click": _vm.sendConfirmationCode
    }
  }, [_vm._v(" Sign in ")])], 1)], 1)])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "security-text"
  }, [_vm._v(" Your connection is protected by SSL "), _c('img', {
    attrs: {
      "src": require("@/assets/icons/padlock.svg")
    }
  })]);
}]

export { render, staticRenderFns }