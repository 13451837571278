



































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniLine from "@/components/layout/SygniLine.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import LoginForm from "@/modules/auth/components/LoginForm.vue";
import LiveDemoForm from "@/modules/auth/components/LiveDemoForm.vue";
import SygniSelectableTitle from "@/components/layout/SygniSelectableTitle.vue";
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import Utils from '@/modules/shared/utils/utils';
import { REDIRECTED_PATH } from '@/modules/genprox/genprox.routing';

@Component({
  components: {
    LiveDemoForm, SygniSelectableTitle,
    LoginForm, SygniContainerTitle, SygniCheckbox, SygniLine, SygniRadio, SygniRoundedButton, SygniInput}
})
export default class ConfirmLogin extends Vue {
  confirmationCode: string = '';
  sessionLength: number = 300;
  isLoading: boolean = false;
  interval: any = null;

  async sendConfirmationCode() {
    this.$v.$touch();

    if(!this.$v.$error) {
      this.$v.$reset();
      this.isLoading = true;

      try {
        await this.$store.dispatch('auth/loginWithTwoFactor', {
          token: this.$route.params.token,
          confirmationCode: this.confirmationCode,
        })
        localStorage.removeItem(REDIRECTED_PATH);
      } catch(e) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: errorMessage
        });
      }

      this.isLoading = false;
    }
  }

  validations () {
    return  {
      confirmationCode: { 
        required,
        minLength: minLength(6),
        maxLength: maxLength(6)
      },
    }
  }

  get timerString() {
    const minutes = Math.floor(this.sessionLength / 60);
    const seconds = this.sessionLength % 60;

    return `${Utils.pad(minutes, 2)}:${Utils.pad(seconds, 2)}`;
  }

  beforeMount() {
    if(this.$route?.params?.token === undefined) {
      this.$router.push({ name: 'login' });
    } else {
      this.interval = setInterval(() => {
        this.sessionLength--;

        if(this.sessionLength <= 0) {
          this.$router.push({ name: 'login' });
        }
      }, 1000);
    }
  }

  beforeDestroy() {
    clearInterval(this.interval);
  }
}

